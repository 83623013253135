body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat,Open Sans,Arial,sans-serif;
}

#root {
  height: 100vh;
  --preset-big-header-color: rgba(26,163,193,.9);
  --preset-big-header-color-dark: #02253f;
  color: var(--preset-big-header-color-dark);
}
