:root {
  font-size: 20px;
}


.ParallexSection {
  margin: 0;
  z-index: -1;
}

.App-header {
  z-index: 2;
  position: fixed;
  left: 0;
  height: 100%;
  width: 100px;
  background-color: var(--preset-big-header-color);
  display: flex;
  padding: 30px 0;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.App-header > span {
  padding: 5px 20px
}

.NavBar {
  position: fixed;
  right: 0;
  left: 100px;
  background-color: var(--preset-big-header-color-dark);
  border-bottom: 50px solid var(--preset-big-header-color);
  z-index: 1;
  flex-wrap: wrap;
  align-content: stretch;
  padding: 30px 50px;
}

.NavBar-show{
  display: flex;
}

.NavBar-hidden{
  display: none;
}

.NavBar-content {
  flex-shrink: 1;
  margin: 10px;
  width: 200px;
  padding: 10px 20px;
  border-top: 3px solid var(--preset-big-header-color);
  color: white;
}
.NavBar-content > a {
  color: var(--preset-big-header-color);
  font-weight: 800;
  font-size: 1.5em;
}
.NavBar-content > ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.NavBar-content > ul > li {
  margin: 10px 0;
  font-weight: 600;
  font-size: 1.2em;
}

.options_svg__center-top, .options_svg__center-bottom,
.options_svg__left-center, .options_svg__right-center {
  fill-opacity: 100;
  transform: translate(0px);
  transition: fill-opacity 0.6s, transform 0.6s;
}


.App-body {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  -webkit-perspective: 10px;
}

.ParallexSection {
  z-index: -1;
  display: grid;
  position: relative;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  height: 100vh;
  width: 100vw;
  /* justify-content: center;
  align-items: center; */
  /* overflow-x: hidden; */
}

#fsection {
  display: flex;
  justify-content: center;
  align-items: center;
}

#splashtext {
  transform: translateZ(-5px) scale(1.5);
  -webkit-transform: translateZ(-5px) scale(1.5);
  color: white;
  font-family: 'Fira Mono';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90vw;
}

#title-text{
  letter-spacing: .3rem;
  font-weight: 1000;
  font-size: 4rem;
  font-style: bold;
  color: white;
  bottom: 40px;
  text-shadow:
    /* 0 0 2px #fff,
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 20px #bbb, */
    0 0 40px #bbb,
    0 0 45px #bbb,
    0 0 50px #bbb,
    0 0 75px #bbb;
}



.SplashScrnText {
  font-size: 1.4rem;
  transform: translateZ(-5px) scale(1.5);
  -webkit-transform: translateZ(-5px) scale(1.5);
  text-shadow:
  /* 0 0 2px #fff,
  0 0 5px #fff,
  0 0 10px #fff,
  0 0 20px #bbb, */
  0 0 40px #bbb,
  0 0 45px #bbb,
  0 0 50px #bbb,
  0 0 75px #bbb;
}

.StaticImage {
  position: absolute;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: translateZ(-10px) scale(2);
  -webkit-transform: translateZ(-10px) scale(2);
  z-index: -1;
  border: 0;
}

.SecondSection {
  min-height: 30vh;
  z-index: 1;
  background: white;
  position: relative;
  display: grid;
  grid-template-columns: [c1] 40% [c2] 60% [c3];
  grid-template-rows: 1fr;
  padding: 8% 15%;
  overflow: hidden;
}

#section2-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  grid-column: 2/3;
  grid-row: 1/2;
  margin-left: 50px;
}

#second-h1 {
  font-size: min(2em, 60px);
}

#second-h2 {
  font-size: min(1.8em, 60px);
  font-weight: 400;
}

#laptop-img {
  max-width: 320px;
  width: 80%;
  height: auto;
  align-self: center;
  justify-self: center;
  object-fit: scale-down;
  margin-right: 40px;
}

.show {
  opacity: 1;
  filter: blur(0);
  transition: all 1s;
}

.left-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px);
}

.right-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100px);
}

@media (prefers-reduced-motion) {
  .left-hidden {
    transition: none;
  }
  
  .right-hidden {
    transition: none;
  }
}

.ThirdSection {
  display: grid;
  grid-template-columns: [c1] 4fr [c2] 6fr [c3];
  grid-template-rows: 1fr;
  position: relative;
  background: rgb(218, 218, 218);
  color: var(--preset-big-header-color-dark);
  min-height: 60vh;
  z-index: 1;
  padding: 8% 0px 8% 8%;
}

.ThirdSection > div {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: start;
}

p {
  font-size: min(1.8em, 60px);
}

h1 {
  font-size: min(2.2em, 60px);
}
h2 {
  font-size: min(2em, 60px);
}

#video-in-laptop {
  align-self: center;
  position: relative;
  grid-row: 1/span 2;
  grid-column: 2/3;
  height: 850px;
}

#video-in-laptop > img {
  position: absolute;
  right: -1325px;
  width: 1297px;
  top: 60px;
  height: 700px;
}

#video-in-laptop > video {
  position: absolute;
  width: 1050px;
  top: 95px;
  right: -1202px;
}

#FourthSection > img {
  filter: blur(8px);
}

#FourthSection {
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0% 10vw;
  height: 100vh;
}

.BalloonImgDiv {
  position: relative;
  align-self: center;
  grid-column: 1/2;
  grid-row: 1 / span 3;
}

.BalloonImgDiv > img {
  width: 20vw;
  max-width: 300px;
}

.textSection{
  grid-column: 2/3;
  grid-row: 1 / span 3;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 55vw;
  margin-left: 2vw;
  background-color: rgba(199, 199, 199, 0.932);
  padding: 50px;
  border-radius: 20px;
}

.FifthSection {
  background-color: white;
  z-index: 1;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: 10vh auto;
  padding: 7vh 7vw;
}

.FifthSection > img {
  width: 10vw;
  max-width: 150px;
}

.FifthSection > h1 {
  grid-row: 1 / 2;
  align-self: start;
  justify-self: center;
}

.FifthSection > p {
  grid-row: 2 / 3;
  align-self: start;
  justify-self: center;
  text-align: center;
}

/* #quote {
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
}

#quoter {
  grid-column: 3/4;
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
} */

.SixthSection {
  background-color: white;
  z-index: 1;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 10vh auto;
  column-gap: 5vw;
  padding: 8vh 8vw;

}

.SixthSection > img {
  grid-column: 1/2;
  grid-row: 1/3;
  width: 20vw;
  max-width: 250px;

}

.SixthSection > h1 {
  grid-column: 2/ 3;
  grid-row: 1/2;
}

.SixthSection > p {
  grid-column: 2/3;
  grid-row: 2/3;
}


@media (max-width: 1100px) {
  .SplashScrnText {
    font-size: 1rem;
    width: 200px;
    margin-top: 50px;
  }
  #title-text {
    font-size: 2rem;
  }
  #title-text {
    font-size: 2rem;
  }
  .SecondSection {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #section2-text  {
    margin-left: 0;
  }
  
  #second-h1 {
    font-size: min(1.5em, 30px);
    text-align: center;
  }
  
  #second-h2 {
    font-size: min(1.2em, 60px);
    font-weight: lighter;
    text-align: center;
  }
  
  #laptop-img {
    margin-right: 0;
    margin-bottom: 10%;
  }

  .ThirdSection {
    display: flex;
    min-height: 50vh;
    padding: 10%;
    flex-direction: column;
  }
  
  .ThirdSection > div {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
  }
  
  p {
    font-size: 1.1rem;
  }
  
  h1 {
    font-size: 1.5rem;
    padding-bottom: 0px;
  }
  h2 {
    font-size: 1.3rem;
  }
  
  #video-in-laptop {
    height: 189px;
    width: 350px;
    margin: 20px 0;
  }

  #video-in-laptop > img {
    position: absolute;
    left: 0px;
    width: 350px;
    height: 189px;
    top:0;
  }
  
  #video-in-laptop > video {
    position: absolute;
    width: 285px;
    top: 10px;
    left: 33px;
  }
  
  #FourthSection {
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0;
    height: 115vh;
  }
  #FourthSection > img {
    filter: blur(8px);
  }
  
  .BalloonImgDiv {
    position: relative;
    align-self: center;
    grid-column: 1/2;
    grid-row: 1 / span 3;
    padding: 5vw 0%;
  }
  
  .BalloonImgDiv > img {
    width: 60vw;
    max-width: 250px;
  }

  .textSection {
    position: relative;
    left:-10px;
    width: 80vw;
    margin-left: 0;
    padding-top: 20px;
  }
  
  .textSection > h1 {
    font-size: 1.1rem;
  }

  .textSection > p {
    font-size: 100%;
    margin: 0;
  }
  .FifthSection {
    padding-bottom: 20px;
  }
  .FifthSection > h1 {
    font-size: 1.2rem;
    align-self: end;
  }
  .FifthSection > p {
    padding: 0% 10%;
    grid-column: 1/4;
    grid-row: 2 / 3;
    font-size: 1rem;
  }

  /* #quote {
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;
  }
  
  #quoter {
    grid-column: 3/4;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;
  } */
  .SixthSection {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }
  .SixthSection > img {
    width: 150px;
  }
  .SixthSection > h1 {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .SixthSection > p {
    font-size: 1rem;
    
  }
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(169, 224, 255) 50%, rgb(255, 255, 255) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:absolute;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.content {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:absolute;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}